:root {
  /* Color palette */
  --primary: #ffd130;
  --primary-2: #196049;
  --primary-3: #a8a3d7;
  --primary-4: #f2f1f9;

  --neutral: #343434;
  --neutral-2: #898989;
  --neutral-3: #1960493d;
  --neutral-4: #cacaca;
  --neutral-5: #e0e0e0;
  --neutral-6: #ffffff;

  --semantic: #ff4267;
  --semantic-2: #0890fe;
  --semantic-3: #ffaf2a;
  --semantic-4: #52d5ba;
  --semantic-5: #fb6b18;

  --red: #ff3b30;

  --ion-color-primary: var(--primary);
  --ion-color-primary-rgb: 255, 209, 48;
  --ion-color-primary-contrast: var(--neutral-6);
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0b82a;
  --ion-color-primary-tint: #ffd645;

  --ion-color-secondary: var(--primary-2);
  --ion-color-secondary-rgb: 25, 96, 73;
  --ion-color-secondary-contrast: var(--neutral-6);
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #165440;
  --ion-color-secondary-tint: #30705b;

  --ion-color-tertiary: var(--primary-3);
  --ion-color-tertiary-rgb: 168, 163, 215;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #948fbd;
  --ion-color-tertiary-tint: #b1acdb;

  --ion-color-success: var(--semantic-4);
  --ion-color-success-rgb: 82, 213, 186;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #48bba4;
  --ion-color-success-tint: #63d9c1;

  --ion-color-warning: var(--semantic-3);
  --ion-color-warning-rgb: 255, 175, 42;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e09a25;
  --ion-color-warning-tint: #ffb73f;

  --ion-color-danger: var(--semantic);
  --ion-color-danger-rgb: 255, 66, 103;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e03a5b;
  --ion-color-danger-tint: #ff5576;

  --ion-color-light: var(--primary-4);
  --ion-color-light-rgb: 242, 241, 249;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d5d4db;
  --ion-color-light-tint: #f3f2fa;

  --ion-color-medium: var(--neutral-2);
  --ion-color-medium-rgb: 137, 137, 137;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #797979;
  --ion-color-medium-tint: #959595;

  --ion-color-dark: var(--neutral);
  --ion-color-dark-rgb: 52, 52, 52;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #2e2e2e;
  --ion-color-dark-tint: #484848;

  --ion-font-family: "Poppins", system-ui;
  --shadow: 0px 4px 30px 0px rgba(54, 41, 183, 0.07);
  --shadow-small: 0px -5px 30px 0px rgba(54, 41, 183, 0.07);
}

/* Styles  */

.title {
  font-style: normal;

  &-1 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.75rem;
  }

  &-2 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
  }

  &-3 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

.body {
  font-style: normal;
  &-1 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }

  &-2 {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  &-3 {
    font-size: 0, 875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3125rem;
  }
}

.caption {
  &-regular-12 {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }
  &-1 {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
  }

  &-2 {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
  }
}

.shadow {
  box-shadow: var(--shadow);

  &-small {
    box-shadow: var(--shadow-small);
  }
}

.message {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
  &-danger {
    color: var(--semantic);
  }

  &-success {
    color: var(--semantic-4);
  }

  &-warning {
    color: var(--semantic-3);
  }
}

.text-primary {
  color: var(--primary);
}

.text-white {
  color: var(--neutral-6);
}
.text-dark {
  color: var(--neutral);
}

.text-neutral-2 {
  color: var(--neutral-2);
}

.text-secondary {
  color: var(--primary-2);
}

.bg-primary {
  background-color: var(--primary);
}

.bg-secondary {
  background-color: var(--primary-2);
}
/* Components */

ion-button {
  height: 2.75rem;
  border-radius: 15px;
  --border-radius: 15px;
  --padding-bottom: 0.625rem;
  --padding-top: 0.625rem;
  text-transform: initial;

  @extend .body-1;
}

ion-header {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

ion-header.custom {
  background: var(--ion-color-primary);

  &::before {
    content: "";
    position: absolute;

    background-color: transparent;
    bottom: -50px;
    height: 50px;
    width: 100%;
    border-top-left-radius: 1.875rem;
    border-top-right-radius: 1.875rem;
    box-shadow: 0 -1.875rem 0 0 var(--ion-color-primary);
  }
}

ion-toolbar {
  --background: transparent;

  ion-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  ion-title,
  ion-back-button {
    text-transform: capitalize;
    color: white;
    padding-left: 0px;
    padding-right: 0px;
    @extend .title-2;
  }

  ion-button {
    @extend .title-2;
    ion-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

ion-input.custom,
ion-textarea.custom,
ion-select.custom {
  --color: var(--neutral);
  font-size: 0.875rem;
  font-weight: 500;
  min-height: 2.75rem !important;
  line-height: 1.3125rem;
  --padding-start: 1.5rem !important;
  --padding-end: 0.625rem !important ;
  border-radius: 15px !important;
  --border-radius: 15px !important;
  --border-width: 1px !important;
  border-width: 1px !important;
  --border-color: #cbcbcb;
  border-color: #cbcbcb;
  --placeholder-color: var(--neutral-4);
  --placeholder-font-style: normal;
  --placeholder-font-weight: 500;
  --highlight-color-focused: var(--primary-2);
}

ion-datetime-button {
  &::part(native) {
    color: var(--neutral);
    font-size: 0.875rem;
    font-weight: 500;
    min-height: 2.75rem !important;
    line-height: 1.3125rem;
    border-radius: 15px !important;
    border: 1px solid #cbcbcb;
    background-color: white;
    width: inherit;
  }
}

.custom-alert {
  .alert-wrapper {
    border-radius: 18px;
  }
  .alert-head {
    text-align: center;
    .alert-title {
      @extend .body-1;
      color: var(--neutral);
    }
  }

  .alert-message {
    @extend .body-1;
    text-align: center;
    color: var(--neutral-2);
    border-bottom: 1px solid #ececec;
  }

  .alert-button-group {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    padding: 1rem;
    button {
      flex-grow: 1;
      span {
        justify-content: center;
      }
    }
    .no,
    button:first-child {
      @extend .body-1;
      flex-grow: 1;
      border-radius: 0.9375rem;
      background: var(--primary-4);
      color: var(--neutral);
      text-transform: initial;
    }
    .yes,
    button:last-child {
      @extend .body-1;
      border-radius: 0.9375rem;
      background: var(--primary);
      color: var(--neutral-6);
      text-transform: initial;
    }
  }
}

ion-checkbox {
  --size: 32px;
  --border-color: var(--ion-color-primary);
  --border-color-checked: var(--ion-color-primary);
  --checkbox-background-checked: white !important;
  --checkmark-color: var(--ion-color-primary) !important;

  &::part(container) {
    border-radius: 6px;
    border: 2px solid var(--ion-color-primary);
  }
}

ion-tabs {
  ion-tab-bar {
    padding-top: 1rem;
    padding-bottom: 1rem;

    ion-tab-button {
      --color: var(--neutral-2);
      --color-selected: white;
      &.tab-selected {
        ion-icon {
          background: var(--primary);
          padding: 8px 10px;
          border-radius: 1.25rem;
        }

        ion-label {
          color: var(--primary);
        }
      }
    }
  }
}
